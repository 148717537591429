@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #005f98;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
